<template>
  <Modal @close="handleCloseModal" v-if="isInforModalShown">
    <template v-slot:headline>有効期限の定義</template>
    <template v-slot:body>
      <h4 class="description">■原則発行日の月から6か月後にあたる月同日の前日が有効期限満了日。</h4>
      <div class="ml-10">
        <p>例①：4月20日の場合：6か月後にあたる月の同日＝10月20日→その前日＝10月19日</p>
        <p>例②：6月1日の場合：6か月後にあたる月の同日＝12月1日→その前日＝11月30日</p>
        <p>例③：7月31日の場合：6か月後にあたる月の同日＝1月31日→その前日＝1月30日</p>
        <p>例④：9月1日の場合：6か月後にあたる月の同日＝3月1日→その前日＝2月28日</p>
        <p>※（うるう年なら2月29日）</p>
      </div>
      <h4 class="description mt-20">■応当日がない場合は、その月の末日に満了します（民法第143条第2項）</h4>
      <div class="ml-10">
        <p>例❶：5月31日の場合：6か月後にあたる月の同日＝11月31日は無い→その月の末日＝11月30日</p>
        <p>例❷：8月31日の場合：6か月後にあたる月の同日＝2月31日は無い→その月の末日＝2月28日</p>
        <p>※（うるう年なら2月29日）</p>
      </div>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="handleCloseModal">キャンセル</button>
          </li>
        </ul>
      </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import modal from '@/mixins/plugin/modal';

export default {
  components: {
    Modal,
  },
  mixins: [modal],
  props: {
    handleCloseModal: {
      type: Function,
      required: true
    },
    isInforModalShown: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style scoped>
p {
  font-size: 13px;
}
</style>
